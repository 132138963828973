(function ($) {

	"use strict";

	/* ========================
	 * Global Navigation Toggle
	 * ====================== */
	var $header = $("#header-inner"),
			$headerBtn = $("#navToggleBtn"),
			headerToggleClass = "opened";

	$headerBtn.click(function (e) {
		e.preventDefault(); // Just in case butten is an anchor link
		$header.toggleClass( headerToggleClass );
		return false;
	});

	/* ================
	 * Smooth scrolling
	 * ============== */
	$('a[href*="#"]:not([href="#"])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 500);
				return false;
			}
		}
	});

}(jQuery));